import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './App.css';

const NextGame = () => {
  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextGame, setNextGame] = useState(null);

  useEffect(() => {
    fetchGameData();
  }, []);

  const fetchGameData = async () => {
    try {
      setLoading(true);
      const response = await fetch('/schedule.json');
      
      if (!response.ok) {
        throw new Error(`Failed to fetch schedule: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
      setGameData(data);
      findNextGame(data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching game data:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  const convertUTCToLocalDate = (utcDateStr, utcTimeStr) => {
    try {
      // Parse the time string (e.g., "08:05 PM")
      const [hourMin, period] = utcTimeStr.split(' ');
      const [hourStr, minStr] = hourMin.split(':');
      
      let hour = parseInt(hourStr, 10);
      const minute = parseInt(minStr, 10);
      
      // Convert to 24-hour format
      if (period === 'PM' && hour < 12) hour += 12;
      if (period === 'AM' && hour === 12) hour = 0;
      
      // Create a UTC date object from the date and time strings
      const [year, month, day] = utcDateStr.split('-').map(num => parseInt(num, 10));
      const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, 0));
      
      return utcDate;
    } catch (err) {
      console.error("Error converting UTC date:", err);
      return new Date();
    }
  };

  const findNextGame = (games) => {
    try {
      // Get current date and time in local time
      const now = new Date();
      
      // Convert each game's UTC date/time to local and find the next one
      const upcomingGames = games.map(game => {
        const localDateTime = convertUTCToLocalDate(game.date, game.time);
        return {
          ...game,
          localDateTime
        };
      })
      .filter(game => game.localDateTime > now)
      .sort((a, b) => a.localDateTime - b.localDateTime);
      
      if (upcomingGames.length > 0) {
        setNextGame(upcomingGames[0]);
      } else {
        setNextGame(null);
      }
    } catch (err) {
      setError('Error processing game schedule');
    }
  };

  const formatGameDateTime = (utcDateStr, utcTimeStr, timeZone) => {
    try {
      if (!utcDateStr || !utcTimeStr) return { date: 'TBD', time: 'TBD' };
      
      const gameDateTime = convertUTCToLocalDate(utcDateStr, utcTimeStr);
      
      // Format the date in the specified time zone
      const dateOptions = {
        timeZone,
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      
      // Format the time in the specified time zone
      const timeOptions = {
        timeZone,
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
      
      return {
        date: new Intl.DateTimeFormat('en-US', dateOptions).format(gameDateTime),
        time: new Intl.DateTimeFormat('en-US', timeOptions).format(gameDateTime)
      };
    } catch (err) {
      console.error("Error formatting date/time:", err);
      return { date: utcDateStr, time: utcTimeStr };
    }
  };

  const isDayGame = (utcDateStr, utcTimeStr) => {
    try {
      if (!utcTimeStr) return false;
      
      const gameDateTime = convertUTCToLocalDate(utcDateStr, utcTimeStr);
      const hours = gameDateTime.getHours();
      
      // Consider games before 6:00 PM (18:00) as day games
      return hours < 18;
    } catch (err) {
      return false;
    }
  };

  if (loading) {
    return <div className="loading">Loading next game information...</div>;
  }
  
  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  const isDayGameNext = nextGame && isDayGame(nextGame.date, nextGame.time);

  return (
    <>
      <div className={`next-game ${isDayGameNext ? "day-game" : ""}`}>
        {nextGame ? (
          <>
            <div className="main-info">
              <p>{formatGameDateTime(nextGame.date, nextGame.time, 'America/Chicago').date}</p>
              <p>{nextGame.match}</p>
              <p>It's {nextGame.location.includes("Wrigley Field") || nextGame.location.includes("Sloan Park") ? "a home" : "an away"} game</p>
              {isDayGameNext && (
                <p className="day-game-highlight">
                  It's a day game!
                </p>
              )}
            </div>
            <div className="follow-up">
              {nextGame.time && (
                <>
                  <p>It starts at {formatGameDateTime(nextGame.date, nextGame.time, 'America/Chicago').time} CT</p>
                </>
              )}
              <p>Location: {nextGame.location}</p>
            </div>
          </>
        ) : (
          <p>No upcoming games scheduled</p>
        )}
      </div>
      <div className="emoji-corner">
        <a className="link" href="/">Home</a>
        <span className="separator">|</span>
        <a className="link" href="#7">Next 7 Days</a>
      </div>
    </>
  );
};

export default NextGame;

import React, { useState, useEffect } from 'react';
import './App.css';

const Next7Days = () => {
  const [next7Games, setNext7Games] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGameData = async () => {
      try {
        setLoading(true);
        const response = await fetch('/schedule.json');
        
        if (!response.ok) {
          throw new Error(`Failed to fetch schedule: ${response.status} ${response.statusText}`);
        }
        
        const data = await response.json();
        findNext7Games(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGameData();
  }, []);

  // Convert UTC date and time to local date
  const convertUTCToLocalDate = (utcDateStr, utcTimeStr) => {
    try {
      if (!utcTimeStr) {
        // If no time provided, just convert the date
        const [year, month, day] = utcDateStr.split('-').map(num => parseInt(num, 10));
        return new Date(year, month - 1, day);
      }
      
      // Parse the time string (e.g., "08:05 PM")
      const [hourMin, period] = utcTimeStr.split(' ');
      const [hourStr, minStr] = hourMin.split(':');
      
      let hour = parseInt(hourStr, 10);
      const minute = parseInt(minStr, 10);
      
      // Convert to 24-hour format
      if (period === 'PM' && hour < 12) hour += 12;
      if (period === 'AM' && hour === 12) hour = 0;
      
      // Create a UTC date object from the date and time strings
      const [year, month, day] = utcDateStr.split('-').map(num => parseInt(num, 10));
      const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, 0));
      
      return utcDate;
    } catch (err) {
      return new Date(); // Return current date as fallback
    }
  };

  // Format a UTC time for display in a specific timezone
  const formatGameTime = (utcDateStr, utcTimeStr, timeZone = 'America/Chicago') => {
    try {
      if (!utcTimeStr) return 'TBD';
      
      const gameDateTime = convertUTCToLocalDate(utcDateStr, utcTimeStr);
      
      // Format the time in the specified time zone
      const timeOptions = {
        timeZone,
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      };
      
      return new Intl.DateTimeFormat('en-US', timeOptions).format(gameDateTime);
    } catch (err) {
      return utcTimeStr;
    }
  };

  const findNext7Games = (games) => {
    try {
      // Get today's date in local time
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Start of day
      
      // Create array of the next 7 days
      const next7Days = [...Array(7)].map((_, i) => {
        const nextDay = new Date(today);
        nextDay.setDate(today.getDate() + i);
        return nextDay;
      });
      
      // Process games to get their local dates
      const processedGames = games.map(game => {
        const localDateTime = convertUTCToLocalDate(game.date, game.time);
        return {
          ...game,
          localDateTime
        };
      });
      
      // For each of the next 7 days, find a game or mark as off day
      const gamesForNext7Days = next7Days.map(date => {
        // Set time to start of day for comparison
        const dayStart = new Date(date);
        dayStart.setHours(0, 0, 0, 0);
        
        const dayEnd = new Date(date);
        dayEnd.setHours(23, 59, 59, 999);
        
        // Find a game on this day in local time
        const gameOnThisDay = processedGames.find(game => {
          const gameDate = new Date(game.localDateTime);
          return gameDate >= dayStart && gameDate <= dayEnd;
        });
        
        if (gameOnThisDay) {
          return {
            ...gameOnThisDay,
            date: date, // Use the local date
            originalDate: gameOnThisDay.date, // Keep original UTC date for formatting
            originalTime: gameOnThisDay.time // Keep original UTC time for formatting
          };
        } else {
          return { date: date, off: true };
        }
      });
      
      setNext7Games(gamesForNext7Days);
    } catch (err) {
      setError('Error processing game schedule');
    }
  };

  const formatDate = (date) => {
    const dayOfWeek = date.toLocaleString('en-US', { weekday: 'short' }); // e.g., "Mon"
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    return `${dayOfWeek} ${month}/${day}`;
  };

  // Check if a game is a day game (before 2:00 PM CT)
  const isDayGame = (utcDateStr, utcTimeStr) => {
    try {
      if (!utcTimeStr) return false;
      
      const gameDateTime = convertUTCToLocalDate(utcDateStr, utcTimeStr);
      const hours = gameDateTime.getHours();
      
      // Consider games before 2:00 PM (14:00) as day games
      return hours < 14;
    } catch (err) {
      return false;
    }
  };

  if (loading) {
    return <div className="loading">Loading schedule information...</div>;
  }
  
  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <>
      <h1 className="schedule-title">Cubs Schedule</h1>
      <div className="next-7-days">
        {next7Games.map((game, index) => {
          const formattedDate = formatDate(game.date);
          
          // Determine opponent display
          let opponent = '';
          if (!game.off) {
            const matchParts = game.match.split(' at ');
            if (game.location.includes("Wrigley Field") || game.location.includes("Sloan Park")) {
              opponent = `@Cubs`;
            } else if (matchParts[1]) {
              // For away games, extract the team name
              const awayTeam = matchParts[1].trim();
              // Convert team name to short form if needed
              if (awayTeam === "White Sox") {
                opponent = "@Sox";
              } else if (awayTeam === "Diamondbacks") {
                opponent = "@Diamondbacks";
              } else {
                opponent = `@${awayTeam}`;
              }
            }
          }
          
          // Determine game time display
          const gameTime = game.off ? null : 
            (game.time ? `${formatGameTime(game.originalDate || game.date, game.originalTime || game.time)} CT` : 'TBD');
          
          // Check if it's a day game
          const dayGame = !game.off && isDayGame(game.originalDate || game.date, game.originalTime || game.time);
          
          return (
            <div 
              key={index} 
              className={`game-cell ${game.off ? "off" : game.location.includes("Wrigley Field") || game.location.includes("Sloan Park") ? "home" : "away"} ${dayGame ? "day-game" : ""}`}
            >
              <div className="game-date">{formattedDate}</div>
              <div className={game.off ? "game-status" : "game-opponent"}>{game.off ? "Off Day" : opponent}</div>
              {!game.off && <div className="game-time">{gameTime}</div>}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Next7Days;
import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import GameInfo from './GameInfo';
import NextGame from './NextGame';
import Next7Days from './Next7Days';

// Route logger component
const RouteLogger = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Route change tracking removed
  }, [location]);
  
  return null;
};

const App = () => {
  return (
    <Router>
      <RouteLogger />
      <div>
        <Routes>
          <Route path="/" element={<GameInfo />} />
          <Route path="/next" element={<NextGame />} />
          <Route path="/7" element={<Next7Days />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
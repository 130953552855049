import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './App.css';

const GameInfo = () => {
  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [todaysGame, setTodaysGame] = useState(null);

  useEffect(() => {
    fetchGameData();
  }, []);

  const fetchGameData = async () => {
    try {
      setLoading(true);
      const response = await fetch('/schedule.json');
      
      if (!response.ok) {
        throw new Error(`Failed to fetch schedule: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
      setGameData(data);
      const game = findTodaysGame(data);
      setTodaysGame(game);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching game data:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  const convertUTCToLocalDate = (utcDateStr, utcTimeStr) => {
    try {
      // Parse the time string (e.g., "08:05 PM")
      const [hourMin, period] = utcTimeStr.split(' ');
      const [hourStr, minStr] = hourMin.split(':');
      
      let hour = parseInt(hourStr, 10);
      const minute = parseInt(minStr, 10);
      
      // Convert to 24-hour format
      if (period === 'PM' && hour < 12) hour += 12;
      if (period === 'AM' && hour === 12) hour = 0;
      
      // Create a UTC date object from the date and time strings
      const [year, month, day] = utcDateStr.split('-').map(num => parseInt(num, 10));
      const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, 0));
      
      return utcDate;
    } catch (err) {
      console.error("Error converting UTC date:", err);
      return new Date();
    }
  };

  const findTodaysGame = (games) => {
    if (!games) return null;
    
    // Get today's date in local time
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of day
    
    // Find games that are happening today in local time
    for (const game of games) {
      // Convert the UTC game date/time to local
      const gameDateTime = convertUTCToLocalDate(game.date, game.time);
      
      // Check if the game is today in local time
      const gameDate = new Date(gameDateTime);
      gameDate.setHours(0, 0, 0, 0); // Start of game day
      
      if (gameDate.getTime() === today.getTime()) {
        return game;
      }
    }
    
    return null;
  };

  const formatGameDateTime = (utcDateStr, utcTimeStr, timeZone) => {
    try {
      if (!utcDateStr || !utcTimeStr) return { date: 'TBD', time: 'TBD' };
      
      const gameDateTime = convertUTCToLocalDate(utcDateStr, utcTimeStr);
      
      // Format the date in the specified time zone
      const dateOptions = {
        timeZone,
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric'
      };
      
      // Format the time in the specified time zone
      const timeOptions = { 
        timeZone,
        hour: 'numeric',
        minute: 'numeric',
        hour12: true 
      };
      
      return {
        date: new Intl.DateTimeFormat('en-US', dateOptions).format(gameDateTime),
        time: new Intl.DateTimeFormat('en-US', timeOptions).format(gameDateTime)
      };
    } catch (err) {
      console.error("Error formatting date/time:", err);
      return { date: utcDateStr, time: utcTimeStr };
    }
  };

  const isDayGame = (utcDateStr, utcTimeStr) => {
    try {
      if (!utcTimeStr) return false;
      
      const gameDateTime = convertUTCToLocalDate(utcDateStr, utcTimeStr);
      const hours = gameDateTime.getHours();
      
      // Consider games before 6:00 PM (18:00) as day games
      return hours < 18;
    } catch (err) {
      return false;
    }
  };

  if (loading) {
    return <div className="loading">Loading today's game information...</div>;
  }
  
  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  const isDayGameToday = todaysGame && isDayGame(todaysGame.date, todaysGame.time);

  return (
    <>
      <div className={`game-info ${isDayGameToday ? "day-game" : ""}`}>
        {todaysGame ? (
          <div>
            <div className="main-info">
              <p>Game on</p>
              <p>It's {todaysGame.match}</p>
              <p>It's {todaysGame.location.includes("Wrigley Field") || todaysGame.location.includes("Sloan Park") ? "a home" : "an away"} game</p>
              {isDayGameToday && (
                <p className="day-game-highlight">
                  It's a day game!
                </p>
              )}
            </div>
            <div className="follow-up">
              {todaysGame.time && (
                <>
                  <p>
                    {todaysGame.delayedStart ? (
                      <>
                        <s>{formatGameDateTime(todaysGame.date, todaysGame.time, 'America/Chicago').time}</s> {todaysGame.delayedStart}
                      </>
                    ) : (
                      <>
                        It starts at {formatGameDateTime(todaysGame.date, todaysGame.time, 'America/Chicago').time} CT
                      </>
                    )}
                  </p>
                  <p>Location: {todaysGame.location}</p>
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="main-info">
              <p>No game</p>
              <p>That's a bummer</p>
              <p>It's going to be ok</p>
            </div>
            <div className="follow-up">
              <p>
                They play <Link className="link" to="/next">soon</Link>
              </p>
            </div>
          </>
        )}
      </div>
      <div className="emoji-corner">
        <a className="link" href="#next">Next</a>
        <span className="separator">|</span>
        <a className="link" href="#7">Next 7 Days</a>
      </div>
    </>
  );
};

export default GameInfo;
